import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';

const MyAccordion = ({ dataRow, panels, header, expandedPanel, setExpandedPanel }) => {
    const useStyles = makeStyles((theme) => ({
        root: { width: '90%' },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightBold,
        },
        details: {
            width: "95%",
            marginLeft: 20,
            marginBottom: 20,
        }
    }));
    const classes = useStyles();
    const handleChange = (panel) => (event, isExpanded) => {
        setExpandedPanel(prev => ({ ...prev, [panel]: isExpanded }));
    };

    return (<Accordion expanded={expandedPanel[panels]} onChange={handleChange(panels)} className="my-2 text-capitalize" style={{ fontFamily: 'Math' }}>
        <AccordionSummary aria-controls={`${panels}-content`} id={`${panels}-header`} >
            <ExpandMoreIcon />
            <Typography className={classes.heading} >{header} </Typography>
        </AccordionSummary>
        <div className={classes.details}>
            {dataRow}
        </div>
    </Accordion>)
}

export default MyAccordion