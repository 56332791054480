import { SimpleCard, Breadcrumb } from "@gull";
import { AgGridReact } from 'ag-grid-react';
import { Flip, ToastContainer, toast, Slide } from 'react-toastify';
import { Popover, OverlayTrigger, Badge, Spinner, InputGroup, Button, Col, Card, Form, Row, Table, Modal, Tooltip, Dropdown, DropdownButton, ListGroup, Tab, Tabs, ListGroupItem, Accordion } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import history from "@history.js";
import _ from 'lodash'
import Typography from '@material-ui/core/Typography';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DropdownMenu from "react-bootstrap/DropdownMenu";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import DatePicker from "react-datepicker";
import jsPDF from 'jspdf'
import VirtualizedSelect from 'react-virtualized-select'
import { useFormik } from 'formik';
import { MdStar, MdStarBorder } from "react-icons/md";
import MyInput from "app/views/utils/MyInput";
import MySingleInput from "app/views/utils/MySingleInput";
import MySingleSelect from "app/views/utils/MySingleSelect";
import MyCheckbox from "app/views/utils/MyCheckbox";
import MySelect from "app/views/utils/MySelect";
import MyTextarea from "app/views/utils/MyTextarea";
import MyCreatableSelect from "app/views/utils/MyCreatableSelect";
import MySingleImage from "app/views/utils/MySingleImage";
import MyAccordion from "app/views/utils/MyAccordion";
import Rating from "react-rating";
import XLSX from 'xlsx';
import { CSVLink } from "react-csv";
import 'jspdf-autotable'
import "react-datepicker/dist/react-datepicker.css";
import ImportDynamicColumnKey from "app/views/utils/ImportDynamicColumnKey";
import ReadExcelFile from "app/views/utils/ReadExcelFile";
import MyStage from "app/views/utils/MyStage";
import { fetchRoute, fetchCustomColumn, fetchPipelineStage, fetchFeedbackStage } from "app/redux/actions/RouteActions";
import { validateAndFocus } from "app/views/utils/validateAndFocus";
import { encryptAndReplace } from "app/views/utils/encryptAndReplace";

import { insertAccountActions, getAccountActions, getAdminAccountActions } from "app/redux/actions/AccountActions";
import { insertContactActions, getContactActions, getAdminContactActions } from "app/redux/actions/ContactActions";
import { insertDepartmentActions, getDepartmentActions, getLeadActions, getLeadAdminActions, getOpportunityActions } from "app/redux/actions/DepartmentActions";
import { insertTeamActions, getTeamActions } from "app/redux/actions/TeamActions";
import { insertDesignationActions, getDesignationActions } from "app/redux/actions/DesigntaionActions";
import { insertSubUserActions, getSubUserActions, getRoleActions } from "app/redux/actions/SubUserActions";
import { getProductActions, getActiveProductActions, insertProductManufactureActions, getManufactureActions, insertProductTaxActions, getTaxActions } from "app/redux/actions/ProductActions";
import { insertCampaginActions, getCampaginTypeActions } from "app/redux/actions/CampaignAction";
import { getDepartmentAssignActions, getTeamAssignActions, getExecutiveAssignActions } from "app/redux/actions/AssignActions";
import { getOrderTypeActions, getConfigTypeActions } from "app/redux/actions/OrderActions";
import { getDropdownActions } from "app/redux/actions/SearchDropdownAction";

export {
    SimpleCard, Breadcrumb, AgGridReact, Flip, ToastContainer, toast, Slide, history, Popover, OverlayTrigger, Button, Col, Form, _, Typography, Select, makeAnimated, FullScreen, useFullScreenHandle, ButtonGroup,
    Row, Table, Modal, Tooltip, Dropdown, DropdownButton, DropdownMenu, DropdownToggle, Card, ListGroup, Tab, Tabs, ListGroupItem, Accordion, useSelector, useDispatch, Badge, Spinner, InputGroup, DatePicker, jsPDF,
    getLeadAdminActions, insertAccountActions, getAccountActions, getAdminAccountActions, insertContactActions, getContactActions, getAdminContactActions, insertDepartmentActions, getDepartmentActions,
    getLeadActions, getOpportunityActions, insertTeamActions, getTeamActions, insertDesignationActions, getDesignationActions, insertSubUserActions, getSubUserActions, getRoleActions, MyCreatableSelect,
    getProductActions, getActiveProductActions, insertProductManufactureActions, getManufactureActions, insertProductTaxActions, getTaxActions, insertCampaginActions, MyInput, MySelect, MyTextarea, MySingleImage,
    getCampaginTypeActions, getDepartmentAssignActions, getTeamAssignActions, getExecutiveAssignActions, getOrderTypeActions, getConfigTypeActions, VirtualizedSelect, useFormik, MdStar, MdStarBorder, Rating, CSVLink, MyAccordion,
    MySingleInput, MySingleSelect, MyCheckbox, ImportDynamicColumnKey, ReadExcelFile, MyStage, fetchRoute, fetchCustomColumn, fetchPipelineStage, fetchFeedbackStage, getDropdownActions, validateAndFocus, encryptAndReplace
}

export function base_url() {
    // return 'http://localhost:8001'
    // return 'https://apitesting.teampage.in'
    // return 'https://apidemo.teampage.in'
    return 'https://lcsapi.teampage.in'
    // return 'https://apipm.teampage.in'
}

export const spanHead = {
    color: '#464844a8',
    fontSize: 13.5,
    // marginRight: 120,
    float: 'right',
    fontFamily: 'math'
}
export const modules = {
    toolbar: [
        [{ 'header': [1, 2, false] }],
        [{ 'font': [] }],
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'align': [] }],
        [{ 'color': [] }, { 'background': [] }],
        ['link'],
        ['clean'],
        // ['image'],
    ]
}
export const formats = [
    'header',
    'font',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'size',
    'bold', 'italic', 'underline',
    'align',
    'link',
    // 'image',
    'color', 'background',
]

export const getCustomHeadersAndParams = () => {
    const cacheBuster = new Date().getTime();
    return {
        headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
            'If-Modified-Since': '0',
        },
        params: {
            cache: cacheBuster,
        },
    };
};


export const blockInvalidChar = e => ['e', 'E', '+', '-', '_', '*', '&', '^', '%', '$', '#', '@', '!', '~', '`', ';', ':', '"', '.', '/', '?', '|', '(', ')', '=', '<', '>', ',',
    'a', 'b', 'c', 'd', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
    'A', 'B', 'C', 'D', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '\'', '\\'].includes(e.key) && e.preventDefault();

export const blockInvalidWord = e => ['e', 'E', '+', '-', '_', '*', '&', '^', '%', '$', '#', '@', '!', '~', '`', ';', ':', '"', '/', '?', '|', '(', ')', '=', '<', '>', ',',
    'a', 'b', 'c', 'd', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
    'A', 'B', 'C', 'D', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '\'', '\\'].includes(e.key) && e.preventDefault();

export const blockInvalidComma = e => ['e', 'E', '+', '-', '_', '*', '&', '^', '%', '$', '#', '@', '!', '~', '`', ';', ':', '"', '/', '?', '|', '(', ')', '=', '<', '>', '.',
    'a', 'b', 'c', 'd', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
    'A', 'B', 'C', 'D', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '\'', '\\'].includes(e.key) && e.preventDefault();


export const downloadPdf = (text, data, headers, name) => {
    const doc = new jsPDF()
    doc.text(text, 14, 10)
    doc.autoTable({
        styles: { font: 'times', fontSize: 7 },
        theme: "striped",
        columns: headers.map(col => ({ header: col.label, dataKey: col.key })),
        body: data
    })
    doc.save(`${name}.pdf`)
}
export const exportToExcel = (data, fileName) => {
    let dt = data.map(({ product_id, executive_id, user_name_id, ticket_id, stage_id, ticket_feedback_id, pipeline_id, feedback_finish, feedback_reopen, opportunity_id, opportunity_feedback_id,
        contact_first_name, contact_last_name, task_id, task_feedback_id, updated_at, ...rest }) => rest)
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(dt);
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const excelArrayBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelBlob = new Blob([excelArrayBuffer], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(excelBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName + '.xlsx';
    // Trigger a click event to download the file
    document.body.appendChild(a);
    a.click();
    // Clean up
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
};


export const dataTitle = [
    { value: "Mr.", label: "Mr." },
    { value: "Ms.", label: "Ms." },
    { value: "Mrs.", label: "Mrs." },
    { value: "Dr.", label: "Dr." },
    { value: "Prof.", label: "Prof." },
    { value: "Eng.", label: "Eng." },
    { value: "Adv.", label: "Adv." },
]

export const dataAccoutType = [
    { value: "Analyst", label: "Analyst" },
    { value: "Competitor", label: "Competitor" },
    { value: "Customer", label: "Customer" },
    { value: "Integration", label: "Integration" },
    { value: "Investor", label: "Investor" },
    { value: "Partner", label: "Partner" },
    { value: "Prospect", label: "Prospect" },
    { value: "Reseller", label: "Reseller" },
    { value: "Supplier", label: "Supplier" },
    { value: "Vendor", label: "Vendor" },
]