import React, { Suspense } from "react";
import "../styles/app/app.scss";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import AppContext from "./appContext";
import routes from "./RootRoutes";
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { Store, persistor } from "./redux/Store";
import { renderRoutes } from "react-router-config";
import Auth from "./auth/Auth";
import RootRoutes from "./RootRoutes";
import { Loading } from "@gull";
import { PersistGate } from 'redux-persist/integration/react'
import { ToastContainer, Flip, history } from "../base"
import './custom.css';

function App() {
  // if (process.env.NODE_ENV === 'production') {
  //   disableReactDevTools();
  // }
  return (
    <AppContext.Provider value={{ routes }}>
      <Provider store={Store}>
        <Auth>
          <PersistGate loading={<Loading />} persistor={persistor} >
            <Suspense fallback={<Loading />}>
              <Router history={history} >{renderRoutes(RootRoutes)}</Router>
            </Suspense>
          </PersistGate>
        </Auth>
      </Provider>
      <ToastContainer transition={Flip} position="top-center" autoClose={1500} hideProgressBar={false} closeOnClick={false} pauseOnHover={false} draggable={false} />
    </AppContext.Provider>
  );
}
export default App;