import { combineReducers } from "redux";
import LayoutReducer from "./LayoutReducer";
import AuthReducer from "./AuthReducer";
import AccountReducer from "./AccountReducer";
import ContactReducer from "./ContactReducer";
import DepartmentReducer from "./DepartmentReducer";
import TeamReducer from "./TeamReducer";
import DesignationReducer from "./DesignationReducer";
import SubUserReducer from "./SubUserReducer";
import ProductReducer from "./ProductReducer";
import CampaignReducer from "./CampaignReducer";
import AssignReducer from "./AssignReducer";
import OrderReducer from "./OrderReducer";
import RouteReducer from "./RouteReducer";
// import { CustomReducer, PipelineReducer } from "./CustomReducer";
import CustomReducer from "./CustomReducer";
import PipelineReducer from "./PipelineReducer";
import FeedbackReducer from "./FeedbackReducer";
import SearchDropdownReducer from "./SearchDropdownReducer";

const RootReducer = combineReducers({
  layout: LayoutReducer,
  login: AuthReducer,
  account: AccountReducer,
  contact: ContactReducer,
  department: DepartmentReducer,
  team: TeamReducer,
  designation: DesignationReducer,
  subUser: SubUserReducer,
  product: ProductReducer,
  campaign: CampaignReducer,
  assignVal: AssignReducer,
  order: OrderReducer,
  routes: RouteReducer,
  custom: CustomReducer, // Include directly without additional object
  pipeline: PipelineReducer,
  feedback: FeedbackReducer,
  searchDropdown: SearchDropdownReducer,
});

export default RootReducer;
